import React, { FC } from "react"

export interface Props {
	headline: string
	text: string
	fieldWidth: number
}

export const Disclaimer: FC<Props> = ({ headline, text, fieldWidth }: Props) => (
	<section data-tpl="grid-bas-02">
		<div className="container">
			<div data-tpl="sta02" className="stage">
				<header>
					<h2 className="sub-headline">{headline}</h2>
					<p>{text}</p>
				</header>
			</div>
		</div>
	</section>
)
