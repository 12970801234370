import React, { FC, useRef } from "react"

import { FormProvider, useForm } from "react-hook-form"
import axios from "axios"
import { FormConfig } from "../models/form-config"
import { ComponentFactory } from "./component-factory"


interface FormSubmissionProps {
	loadingCallback: () => void
	progressCallback: (progressState: number) => void
	successCallback: () => void
	errorCallback: () => void
	formConfig: FormConfig
}

function getCookie(name: string): string {
	const nameLenPlus = name.length + 1
	return (
		document.cookie
			.split(";")
			.map((c) => c.trim())
			.filter((cookie) => cookie.slice(0, Math.max(0, nameLenPlus)) === `${name}=`)
			.map((cookie) =>
				decodeURIComponent(cookie.slice(Math.max(0, nameLenPlus)))
			)[0] || undefined
	)
}

export const FormSubmission: FC<FormSubmissionProps> = (
	props: FormSubmissionProps
) => {
	const {
		loadingCallback,
		progressCallback,
		successCallback,
		errorCallback,
		formConfig,
	} = props

	const methods = useForm({
		mode: "onBlur",
		reValidateMode: "onChange",
	})
	const form = useRef()
	

	const email = useRef({})
	email.current = methods.watch("email", "")

	const onSubmit = methods.handleSubmit(() => {
		methods.trigger()

		const data = new FormData(form.current)

		data.delete(`uploads__drop`)
		methods
			.getValues("uploads")
			.forEach((file: any) => data.append("uploads__drop", file))
		loadingCallback()
		axios
			.post(formConfig.uploadEndpoint, data, {
				headers: {
					"Content-Type": `multipart/form-data;`,
					"XSRF-TOKEN": getCookie(`XSRF-TOKEN`),
				},
				onUploadProgress: (progressEvent: any) => {
					const percent = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					progressCallback(percent)
				},
			})
			.then(() => successCallback())
			.catch(() => errorCallback())
	})
	return (
		<div data-tpl="form-v2" data-module="form-v2" data-module-config>
			<FormProvider {...methods}>
				<form ref={form} onSubmit={onSubmit}>
					{formConfig.formRows.map((formRow) => (
						<div className="row">
							{formRow.formFields.map((field) => (
								<ComponentFactory {...field} />
							))}
						</div>
					))}
					<div className="row">
						<div className="col-sm-0 col-md-3" />
						<div className="col-sm-12 col-md-6">
							<div className="field-group__container">
								<button
									className="color-cta-4 btn--full-width btn"
									type="submit"
								>
									Absenden
								</button>
							</div>
						</div>
						<div className="col-sm-0 col-md-3" />
					</div>
				</form>
			</FormProvider>
		</div>
	)
}
