import React, { FC } from "react"

import { RegisterOptions, useFormContext } from "react-hook-form"
import { Validator } from "../../models/form-config"

export interface TextAreaProps {
	description: string
	inputName: string
	fieldWidth: number
	validator: Validator | RegisterOptions
}

function transformValidator(validator: Validator): RegisterOptions {
	return {
		required: validator.required,
		maxLength: validator.maxLength,
		pattern: validator.pattern && {
			message: validator?.pattern?.message,
			value: new RegExp(validator?.pattern?.value, "g"),
		},
	} as RegisterOptions
}

export const TextArea: FC<TextAreaProps> = (props: TextAreaProps) => {
	const { inputName, description, validator, fieldWidth } = props
	const { errors, register } = useFormContext()

	const realValidator: RegisterOptions = (validator as Validator).customValidate
		? transformValidator(validator as Validator)
		: (validator as RegisterOptions)

	return (
		<div className={`col-12 col-md-${fieldWidth}`}>
			<div className={`field-group ${errors && errors[inputName] ? "error" : ""} `}>
				<label htmlFor={inputName}>{description}</label>
				<div className="field-group__container field-group__container--has-inner">
					<div className="form-control__container">
						<textarea
							id={inputName}
							name={inputName}
							ref={register(realValidator)}
							className="form-control__field"
							aria-autocomplete="none"
							defaultValue=""
							rows={7}
						/>
						<div className="form-control__btns" />
					</div>
					<div className="field-group__btns " />
				</div>
				{errors && errors[inputName] && (
					<span className="error-block">
						{errors[inputName].type === "required"
							? "Die Eingabe wird benötigt"
							: ""}
						{errors[inputName].type === "pattern" ? errors[inputName].message : ""}
						{errors[inputName].type === "maxLength" ? "Die Eingabe ist zu lang" : ""}
					</span>
				)}
			</div>
		</div>
	)
}
