import React, { FC, useCallback, useEffect, useState } from "react"
import Dropzone, { DropzoneOptions } from "react-dropzone"
import { useFormContext } from "react-hook-form"
import * as _ from "lodash"
import { Dropdown } from "./dropdown"
import { PdfPreview } from "../pdf-preview"

interface DropZoneProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string
	options: string[]
	freetext?: string
}

interface DropZoneErrorTypes {
	[key: string]: string | boolean | number
}

const dropZoneError: DropZoneErrorTypes = {
	toomanyfiles: "Maximal 10 Dateien erlaubt",
	filetoolarge: "Dateien dürfen maximal 10 MB groß sein",
	fileinvalidtype:
		"Das Dateiformat ist ungültig. Es können nur PDF-Dokumente und Bilder im JPG- Format hinzugefügt werden.",
}

export const DropZone: FC<DropZoneProps> = (props: DropZoneProps) => {
	const { name, label = name, options, freetext} = props

	const { register, unregister, setValue, errors } = useFormContext()

	const [files, setFiles] = useState<File[]>([])

	const [dropError, setDropError] = useState<string>("")

	const [hasRejectedFile, setHasRejectedFile] = useState<boolean>(false)

	const onDrop = useCallback<DropzoneOptions["onDrop"]>(
		(droppedFiles, rejectedFiles) => {
			setHasRejectedFile(rejectedFiles && rejectedFiles.length > 0)
			setDropError(
				rejectedFiles.length > 0
					? rejectedFiles[0].errors[0].code.replace(/-/g, "")
					: ""
			)
			const tempFiles = _.uniqBy([...files, ...droppedFiles], "path")
			setValue(name, tempFiles, { shouldValidate: true })
			setValue("uploads__drop", tempFiles, { shouldValidate: true })
			setFiles(tempFiles)
		},
		[setValue, name, files, setFiles]
	)
	useEffect(() => {
		register(name, { required: true })
		return () => {
			unregister(name)
		}
	}, [register, unregister, name])

	function removeFromIndex(index: number) {
		const tempFiles = files.splice(0)
		tempFiles.splice(index, 1)
		unregister(`file-${index}`)
		setFiles(tempFiles)
		setValue(name, tempFiles, { shouldValidate: true })
	}

	return (
		<div className="dropzone__wrapper">
			{files &&
				files.length > 0 &&
				files.map((file: any, index) => (
					<div key={file.path} className="row" style={{ paddingBottom: "1rem" }}>
						{file.path.includes(".pdf") && (
							<div className="col-sm-4" key={file.path}>
								<PdfPreview file={file} />
							</div>
						)}

						{file.path.includes(".jpg") && (
							<div className="col-sm-4" key={file.path}>
								<img
									src={URL.createObjectURL(file)}
									alt={file.path}
									className="img-responsive"
								/>
							</div>
						)}
						<div className="col-sm-8">
							<Dropdown
								inputName="fileInformation"
								options={options}
								description="Nachweisart"
								isArray
								arrayIndex={index}
								freetext={freetext}
							/>

							<button
								className="color-cta-2 btn"
								onClick={() => removeFromIndex(index)}
								type="button"
							>
								Datei entfernen
							</button>
						</div>
						<div className="col-sm-12">
							<hr data-tpl="lin01" className="color-line-1" />
						</div>
					</div>
				))}
			<div className="row">
				<div className="col-sm-12">
					<div className="field-group">
						<Dropzone
							onDrop={onDrop}
							maxSize={10*1000*1000}
							accept={{"image/*": [".jpeg", ".jpg"],'application/pdf': [".pdf"],}}
							maxFiles={10}
						>
							{({ getRootProps, getInputProps }) => (
								<div
									{...getRootProps()}
									className={`dropzone__lew ${
										errors && errors[name] ? "error" : ""
									}`}
								>
									<input {...getInputProps()} id={label} name="uploads__drop" />
									<p>
										Laden Sie hier ihre Datei(en) entweder per Drag & Drop oder per
										Klick auf das Symbol hoch.
									</p>
									<img
										src="/images/upload-pikto.png"
										alt="piktogram"
										style={{ maxWidth: "100px", paddingBottom: "1rem" }}
									/>
								</div>
							)}
						</Dropzone>
						{errors && errors[name] && (
							<span className="error-block">
								{errors[name].type === "required" ? "Die Eingabe wird benötigt" : ""}
							</span>
						)}
						{hasRejectedFile && (
							<span className="error-block">{dropZoneError[dropError]}</span>
						)}
					</div>
				</div>
			</div>

			
		</div>
	)
}

DropZone.defaultProps = {
	label: "",
	freetext: undefined
}
