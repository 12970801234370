import React, { FC } from "react"
import { FormField } from "../models/form-config"

import { TextInput } from "./factory-components/text-input"
import { EmailValidator } from "./factory-components/email-validator"
import { Dropdown } from "./factory-components/dropdown"
import { TextArea } from "./factory-components/text-area"
import { Disclaimer } from "./factory-components/disclaimer"
import {DropZone } from "./factory-components/drop-zone"

const typeToComponentMap: { [key: string]: FC<any> } = {
	TextInput,
	EmailValidator,
	Dropdown,
	TextArea,
	Disclaimer,
	DropZone
}

export const ComponentFactory: FC<FormField> = (props: FormField) => {
	const { type, properties } = props

	if (typeof typeToComponentMap[type] === "undefined") {
		return <div>{`No component for type ${type}`}</div>
	}

	return React.createElement(
		typeToComponentMap[type],
		properties,

		<ComponentFactory type={type} properties={properties} />
	)
}
