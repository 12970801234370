import React, { FC, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { TextInput } from "./text-input"

export interface Props {
	fieldWidth?: number
}
export const EmailValidator: FC<Props> = (props: Props) => {
	const { fieldWidth } = props

	const methods = useFormContext()

	const email = useRef({})
	email.current = methods.watch("email", "")

	return (
		<>
			<TextInput
				inputName="email"
				description="E-Mail Adresse"
				inputType="text"
				fieldWidth={fieldWidth}
				validator={{
					required: true,
					pattern: {
						value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
						message: "Ungültige E-Mail Adresse",
					},
				}}
			/>
			<TextInput
				inputName="emailRepeat"
				description="Bestätigung E-Mail Adresse"
				inputType="text"
				fieldWidth={fieldWidth}
				validator={{
					validate: (value) =>
						value === email.current || "Die Email Adressen stimmen nicht überein",
					required: true,
					pattern: {
						value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
						message: "Ungültige E-Mail Adresse",
					},
				}}
			/>
		</>
	)
}

EmailValidator.defaultProps = {
	fieldWidth: 6,
}
