import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { UploadContainer } from "./container/upload-container"
import { biomasseForm, CustomFormContext } from "./custom-form-context"

const baseUrl = document.querySelector("base").getAttribute("href")
const rootElement = document.querySelector("#renderBody")

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<Routes>
			<Route 
			path="/" 
			element={
				<CustomFormContext.Provider value={biomasseForm}>
					<UploadContainer />
    </CustomFormContext.Provider>
			}>
			</Route>
			<Route 
				path="/biomasse"
				element={
					<CustomFormContext.Provider value={biomasseForm}>
						<UploadContainer />
     </CustomFormContext.Provider>
				}>
			</Route>
		</Routes>
	</BrowserRouter>,
	rootElement
)
