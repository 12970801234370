import React, { FC, useContext, useState } from "react"

import { FormSubmission } from "../components/form-submission"
import "./upload.css"
import { CustomFormContext } from "../custom-form-context"
import { ClipLoader } from "react-spinners"


export const UploadContainer: FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isSuccess, setIsSuccess] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)
	const [progressState, setProgressState] = useState<number>(0)

	const formConfig = useContext(CustomFormContext)

	return (
		<>
			{!isLoading && !isSuccess && !isError && (
				<div data-tpl="blanko-overview">
					<header>
						<section data-tpl="grid-bas-02">
							<div className="container">
								<div data-tpl="sta02" className="stage">
									<header>
										<h2 className="headline">{formConfig.formTitle}</h2>
									</header>
								</div>
							</div>
						</section>
					</header>
					<main>
						<section data-tpl="grid-bas-02">
							<div className="container">
								<FormSubmission
									formConfig={formConfig}
									loadingCallback={() => {
										setIsLoading(true)
									}}
									progressCallback={(res: number) => {
										setProgressState(res)
									}}
									successCallback={() => {
										setIsSuccess(true)
										setIsLoading(false)
									}}
									errorCallback={() => {
										setIsError(true)
										setIsLoading(false)
									}}
								/>
							</div>
						</section>
					</main>
				</div>
			)}
			{isLoading && (
				<div data-tpl="blanko-overview">
					<main>
						<section data-tpl="grid-bas-02">
							<div className="container" style={{ textAlign: "center" }}>
								<h1>Ihre Daten werden übertragen</h1>
								{isLoading && progressState < 100 && (
									<progress value={progressState} max="100" />
								)}
								{isLoading && progressState === 100 && (
									<ClipLoader size={150} color="#123abc" loading={isLoading} />
								)}
							</div>
						</section>
					</main>
				</div>
			)}
			{!isLoading && isSuccess && (
				<div data-tpl="blanko-overview">
					<main>
						<section data-tpl="grid-bas-02">
							<div className="container" style={{ textAlign: "center" }}>
								<h1>
									Vielen Dank für Ihre Übermittlung. Sie erhalten in Kürze eine
									Bestätigungsmail.
								</h1>
								<button
									className="color-cta-3 commodityOrderButton btn"
									type="button"
									onClick={() => setIsSuccess(false)}
								>
									Zurück
								</button>
							</div>
						</section>
					</main>
				</div>
			)}
			{!isLoading && isError && (
				<div data-tpl="blanko-overview">
					<main>
						<section data-tpl="grid-bas-02">
							<div className="container" style={{ textAlign: "center" }}>
								<h1>Bei der Übermittlung ist ein Fehler aufgetreten.</h1>
								<h2>Bitte versuchen Sie es erneut</h2>
								<button
									className="color-cta-3 commodityOrderButton btn"
									type="button"
									onClick={() => setIsError(false)}
								>
									Zurück
								</button>
							</div>
						</section>
					</main>
				</div>
			)}
		</>
	)
}
